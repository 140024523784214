import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col, Table, Typography, Tooltip, Button, message, Input } from 'antd';
import AxiosOICStat from '../../AxiosOICStat';
import { useSelector } from 'react-redux';
import { FilterDropdown } from '../../components/FilterDropdown';
import '../../style/external/table.css';
import * as XLSX from 'xlsx';
import DataTable from 'components/DataTable';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const { Search } = Input;

export function IndicatorsList() {
    const [indicators, setIndicators] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [downloading, setDownloadLoading] = React.useState(true);
    const [searchValue, setSearchValue] = React.useState('');
    const { t } = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
 

    const unitsState = useSelector((state) => state.units);    
    const categoriesState = useSelector((state) => state.categories);
    const sourcesState = useSelector((state) => state.sources);
    

    // Define filter options for Units, Categories, and Sources
    const unitFilterOptions = useMemo(() => createFilterOptions(unitsState.value, 'unit_name_eng'), [unitsState.value]);

    const categoryFilterOptions = useMemo(() => createFilterOptions(categoriesState.value, 'cat_name_eng'), [categoriesState.value]);
    const sourceFilterOptions = useMemo(() => createFilterOptions(sourcesState.value, 'source_name_eng'), [sourcesState.value]);


    // Function to create filter options from state values
    function createFilterOptions(stateValues, nameKey) {
        const uniqueNames = new Set(Object.values(stateValues).map(item => item[nameKey]));
        return Array.from(uniqueNames).map(name => ({ text: name, value: name }));
    }

    /*useEffect(() => {
        setLoading(true)
        AxiosOICStat.get('indicators/nonprivate-descriptions')
            .then((response) => {
                setIndicators(response.data);
                setFilteredData(response.data);
            })
            .catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false); 
            });
    }, []);*/

    const handleExport = async () => {
        try {
            setDownloadLoading(true);
            const response = await AxiosOICStat.get('/indicators/indicators-csv/download', {
                responseType: 'blob', // This tells Axios to handle the response as a Blob
            });

            // Use FileReader to read the blob's data
            const reader = new FileReader();
            reader.onload = (evt) => {
                const data = evt.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });

                // Assuming there is at least one worksheet in the workbook
                const wsname = workbook.SheetNames[0];
                const ws = workbook.Sheets[wsname];

                // Convert the worksheet to JSON, specifying raw format for dates
                const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false, dateNF: 'yyyy-mm-dd' });

                const newWorkbook = XLSX.utils.book_new();
                const newWs = XLSX.utils.json_to_sheet(jsonData, {
                    cellDates: true,
                    dateNF: 'yyyy-mm-dd'  // Ensure date format is consistent
                });

                XLSX.utils.book_append_sheet(newWorkbook, newWs, "Indicators");
                XLSX.writeFile(newWorkbook, 'Indicator_Data.xlsx');
            };
            reader.readAsBinaryString(response.data);
        } catch (error) {
            message.error('Failed to download XLSX');
            console.error('Download XLSX error:', error);
        } finally {
            setDownloadLoading(false);
        }
    };
    const handleSearch = (value) => {
        setSearchValue(value);
        const filtered = indicators.filter(indicator => {
            const indicatorName = indicator.ind_name_eng || '';
            return indicatorName.toLowerCase().includes(value.toLowerCase());
            
        });
        setFilteredData(filtered)
    }

    const renderSourceName = (sourceCode) => {
        // Render based on the selected language
        if (language === 'en') {
          return sourcesState.value[sourceCode]?.source_name_eng || sourceCode; // English name
        } else if (language === 'fr') {
          return sourcesState.value[sourceCode]?.source_name_fr || sourceCode; // French name
        } else { // Default to Arabic
          return sourcesState.value[sourceCode]?.source_name_arb || sourceCode; // Arabic name
        }
      };

    const renderUnitName = (unitCode) => {
      // Render based on the selected language
      if (language === 'en') {
        return unitsState.value[unitCode]?.unit_name_eng || unitCode; // English name
      } else if (language === 'fr') {
        return unitsState.value[unitCode]?.unit_name_fr || unitCode; // French name
      } else { // Default to Arabic
        return unitsState.value[unitCode]?.unit_name_arb || unitCode; // Arabic name
      }
    };

    const renderCategoryName = (catCode) => {
        // Render based on the selected language
        if (language === 'en') {
          return categoriesState.value[catCode]?.cat_name_eng || catCode; // English name
        } else if (language === 'fr') {
          return categoriesState.value[catCode]?.cat_name_fr || catCode; // French name
        } else { // Default to Arabic
          return categoriesState.value[catCode]?.cat_name_arb || catCode; // Arabic name
        }
      };
   
    const columns = [
        {
            title: t('ind_list.category'), 
            dataIndex: 'cat_code',
            filters: categoryFilterOptions,
            width: 150,
           
            onFilter: (value, record) => categoriesState.value[record.cat_code]?.cat_name_eng === value,
            sorter: (a, b) => {
                const categoryA = categoriesState.value[a.cat_code]?.cat_name_eng || a.cat_code;
                const categoryB = categoriesState.value[b.cat_code]?.cat_name_eng || b.cat_code;
                return categoryA.localeCompare(categoryB);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={categoryFilterOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
            render: renderCategoryName,
            responsive: ['sm', 'xs'],  
        },
        
        {
            title: t('ind_list.ind_name'),
            dataIndex: language === 'en' ? 'ind_name_eng' 
                 : language === 'fr' ? 'ind_name_fr' 
                 : 'ind_name_arb', 
            width: 300,
            sorter: (a, b) => a.ind_name_eng.localeCompare(b.ind_name_eng),
            render: (indNameEng, record) => <strong>{indNameEng}</strong>,
            responsive: ['sm', 'xs'],  
        },
        {
            title: t('ind_list.unit'),
            dataIndex: 'unit_code',
            width: 150,
            filters: unitFilterOptions,
            onFilter: (value, record) => unitsState.value[record.unit_code]?.unit_name_eng === value,
            sorter: (a, b) => {
                const unitA = unitsState.value[a.unit_code]?.unit_name_eng || a.unit_code;
                const unitB = unitsState.value[b.unit_code]?.unit_name_eng || b.unit_code;
                return unitA.localeCompare(unitB);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={unitFilterOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
            render: renderUnitName,
            responsive: ['sm', 'xs'],  
        },
        {
            title: t('ind_list.def'), 
            dataIndex: language === 'en' ? 'ind_eng_exp' 
                  : language === 'fr' ? 'ind_fr_exp' 
                  : 'ind_arb_exp',
            responsive: ['md', 'xs'],  
        },
        {
            title: t('ind_list.source'),
            dataIndex: 'source_code',
            width: 400,
            filters: sourceFilterOptions,

            onFilter: (value, record) => sourcesState.value[record.source_code]?.source_name_eng === value,
            sorter: (a, b) => {
                const sourceA = sourcesState.value[a.source_code]?.source_name_eng || a.source_code;
                const sourceB = sourcesState.value[b.source_code]?.source_name_eng || b.source_code;
                return sourceA.localeCompare(sourceB);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <FilterDropdown
                    options={sourceFilterOptions}
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                />
            ),
            render: renderSourceName,
            responsive: ['lg', 'xs'],  
        },
    
    ];
    const indicatorsState = useSelector((state) => state.privateIndicators);
    console.log(indicatorsState);
    useEffect(() => {
        try {
        
            const indicatorsArray = Object.values(indicatorsState.value);
            if(indicatorsArray.length > 0){
                indicatorsArray.sort((a, b) => {
                    
                    const categoryA = categoriesState.value[a.cat_code]?.cat_name_eng || "";
                    const categoryB = categoriesState.value[b.cat_code]?.cat_name_eng || "";
                    const indicatorA = a.ind_name_eng || "";
                    const indicatorB = b.ind_name_eng || "";
    
                    if (categoryA !== categoryB) {
                        return categoryA.localeCompare(categoryB);
                    }
                    return indicatorA.localeCompare(indicatorB);
                });

                setIndicators(indicatorsArray);
                setFilteredData(indicatorsArray);
                setLoading(false);
            }
         
        } catch (err) {
            console.log(err);
        }
       
    }, [indicatorsState]);

    

    return (
        <Row>
            <Col xs={24}>

                <div style={{padding:'10px 10px', margin:'10px', background:'white', fontWeight:'bold', borderRadius:'10px'}}>
                    <Text style={{fontSize:'14px', fontFamily:'Roboto, sans-serif' }}>{t('ind_list.total_num_inds')}: </Text>
                    <Text style={{color:'red'}}>{!loading && indicators.length}</Text>
                    <Button type="primary" onClick={handleExport} style={{ margin: '2px 10px 10px 50px' }}>
                            {t('ind_list.export_to_excel')}
                    </Button>
                    <Search
                                placeholder="Search"
                                allowClear
                                value={searchValue}
                                onChange={(e) => handleSearch(e.target.value)}
                                style={{ marginBottom: 10 }}
                            />
                </div>                
                <div className="custom-table-container" style={{
                    maxHeight: "100vh", /* Maximum height of the table container relative to the viewport height */
                    overflowY: "auto" /* Enable vertical scrolling when content exceeds the max height */
                }}>
                    <Table
                    className="custom-table"
                    rowKey="ind_code"
                    dataSource={filteredData}
                    columns={columns}
                    scroll={{ x: true }}
                    size="small"
                    pagination={{ pageSize: "" }}
                    onChange={(pagination, filters, sorter, extra) => {
                        setFilteredData(extra.currentDataSource);
                    }}
                    loading={{ spinning: loading, tip: 'Loading' }}
                    />
                </div>                
                
            </Col>
    
           
        </Row>

        
    );
};