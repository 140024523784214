import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AxiosOICStat from '../AxiosOICStat';

export const fetchPrivateIndicators = createAsyncThunk(
    'indicators/fetchPrivateIndicators',
    async () => {
        const response = await AxiosOICStat.get(`indicators/descriptions`);
        let indicators = {}; 
        response.data.forEach(indicator => {
            if (!indicator.is_private) { // Add only non-private indicators to the object.
                indicators[indicator.ind_code] = indicator;
            }
            else {
                
            }
        });
        return indicators;
    }
);

export const privateIndicatorsSlice = createSlice({
    name: 'private_indicators',
    initialState: {
        status: 'idle',
        value: [],
    },
    reducers: {
        setIndicators(state, action) {
            state.value = action.payload;
        },
        setIndicator(state, action) {
            state.value[action.payload.ind_code] = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPrivateIndicators.pending, (state) => {
            state.status = 'loading';
        }).addCase(fetchPrivateIndicators.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.value = action.payload;
        }).addCase(fetchPrivateIndicators.rejected, (state) => {
            state.status = 'failed';
        });
    },
});

export const { setIndicator } = privateIndicatorsSlice.actions;

export default privateIndicatorsSlice.reducer;
