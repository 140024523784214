import { Row, Col, Card, Typography } from 'antd';
import '../../style/external/dataSource.css';
import { useTranslation } from 'react-i18next';
const { Text } = Typography;

const baseURL = `${window.location.protocol}//${window.location.hostname}${process.env.REACT_APP_API_URL}static/data_source_images/`;


export function DataSource () {
  const { t } = useTranslation();

  const logos = [
    { name: t('sources.cred'), src: 'https://www.sesric.org/imgs/CRED_EMDAT.jpg', url: 'https://public.emdat.be/' },
    { name: t('sources.clarivate'), src: 'https://www.sesric.org/imgs/CLARIVATE ANALYTICS.png', url: 'https://access.clarivate.com/login?app=wos&alternative=true&shibShireURL=https:%2F%2Fwww.webofknowledge.com%2F%3Fauth%3DShibboleth&shibReturnURL=https:%2F%2Fwww.webofknowledge.com%2F%3Fmode%3DNextgen%26action%3Dtransfer%26path%3D%252Fwos%26DestApp%3DUA&referrer=mode%3DNextgen%26path%3D%252Fwos%26DestApp%3DUA%26action%3Dtransfer&roaming=true' },
    { name: t('sources.fao'), src: 'https://www.sesric.org/imgs/FAO_FAOSTAT.jpg', url: 'http://www.fao.org' },
    { name: t('sources.globalCarbonAtlas'), src: 'https://www.sesric.org/imgs/GLOBAL_CARBON_ATLAS.jfif', url: 'http://www.globalcarbonatlas.org/en/CO2-emissions' },
    { name: t('sources.iea'), src: 'https://www.sesric.org/imgs/IEA.jpg', url: 'https://www.iea.org' },
    { name: t('sources.ifsb'), src: 'https://www.sesric.org/imgs/IFSB.png', url: 'https://www.ifsb.org' },
    { name: t('sources.ilo'), src: 'https://www.sesric.org/imgs/ILO.png', url: 'https://www.ilo.org' },
    { name: t('sources.imf'), src: 'https://www.sesric.org/imgs/IMF.png', url: 'https://www.imf.org' },
    { name: t('sources.irena'), src: 'https://www.sesric.org/imgs/IRENA.png', url: 'https://www.irena.org/' },
    { name: t('sources.oica'), src: 'https://www.sesric.org/imgs/OICA.jpg', url: 'http://www.oica.net/' },
    { name: t('sources.itu'), src: 'https://www.sesric.org/imgs/ITU.png', url: 'https://www.itu.int' },
    { name: t('sources.iucn'), src: 'https://www.sesric.org/imgs/IUCN.png', url: 'https://www.iucn.org' },
    { name: t('sources.unaids'), src: 'https://www.sesric.org/imgs/UNAIDS.jpg', url: 'https://www.unaids.org/en' },
    { name: t('sources.opec'), src: 'https://www.sesric.org/imgs/OPEC.png', url: 'https://www.opec.org/opec_web/en/' },
    { name: t('sources.oecd'), src: 'https://www.sesric.org/imgs/OECD.png', url: 'http://www.oecd.org/' },
    { name: t('sources.unesco'), src: 'https://www.sesric.org/imgs/UNESCO_UIS.png', url: 'http://uis.unesco.org' },
    { name: t('sources.unep'), src: 'https://www.sesric.org/imgs/UNEP.png', url: 'https://web.unep.org/' },
    { name: t('sources.unctad'), src: 'https://www.sesric.org/imgs/UNCTAD.jpg', url: 'https://www.unctad.org/' },
    { name: t('sources.undp'), src: 'https://www.sesric.org/imgs/UNDP_HDRO.png', url: 'http://hdr.undp.org/' },
    { name: t('sources.unicef'), src: 'https://www.sesric.org/imgs/UNICEF.jpg', url: 'https://www.unicef.org/' },
    { name: t('sources.unido'), src: 'https://www.sesric.org/imgs/UNIDO.png', url: 'https://www.unido.org/' },
    { name: t('sources.unodc'), src: 'https://www.sesric.org/imgs/UNODC.png', url: 'https://www.unodc.org' },
    { name: t('sources.unpd'), src: 'https://www.sesric.org/imgs/UNPD.jpg', url: 'https://www.un.org/development/desa/pd/' },
    { name: t('sources.unsd'), src: 'https://www.sesric.org/imgs/UNSD.png', url: 'https://unstats.un.org/home/' },
    { name: t('sources.usda'), src: 'https://www.sesric.org/imgs/USDA_FAS.jpg', url: 'https://apps.fas.usda.gov/psdonline/app/index.html#/app/home' },
    { name: t('sources.worldBank'), src: 'https://www.sesric.org/imgs/WORLD_BANK.png', url: 'https://www.worldbank.org/en/home' },
    { name: t('sources.who'), src: 'https://www.sesric.org/imgs/WHO.png', url: 'https://www.who.int' },
    { name: t('sources.wipo'), src: 'https://www.sesric.org/imgs/WIPO.jpg', url: 'https://www.wipo.int/portal/en/index.html' },
    { name: t('sources.worldsteel'), src: 'https://www.sesric.org/imgs/WORLDSTEEL.png', url: 'https://www.worldsteel.org/' },
    { name: t('sources.wto'), src: 'https://www.sesric.org/imgs/WTO.png', url: 'https://www.wto.org/' },
    { name: t('sources.unwto'), src: 'https://www.sesric.org/imgs/UNWTO.png', url: 'https://www.unwto.org' },
  ];

  const logosWithBaseURL = logos.map(logo => ({
    name: logo.name,
    src: logo.src.replace('https://www.sesric.org/imgs/', baseURL),
    url: logo.url
  }));
  

  return (
    <div className="logo-grid" style={{ backgroundColor: 'white', margin:'10px', borderRadius:'10px'}}>
      <div style={{ padding: '20px' }}>
        <Text style={{ fontSize: '14px', fontFamily:'Roboto, sans-serif', fontWeight:'300' }}>
          {t('sources.exp')}
        </Text>
      </div>
      
      <Row gutter={[16, 16]} style={{ padding:'0px 30px' }}> 
        {logosWithBaseURL.map((logo, index) => (
          <Col key={index} xs={24} sm={12} md={8} lg={6} xl={4}>
            <a href={logo.url || '#'} target="_blank" rel="noopener noreferrer">
              <Card hoverable className="logo-card">
                <div className="logo-container">
                  <img src={logo.src} alt={logo.name} style={{ maxWidth: '100%', display: 'block', margin: 'auto' }} />
                  <div className="logo-title">{logo.name}</div>
                </div>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
    </div>
  );
}