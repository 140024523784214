import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export function Features() {
    const { t } = useTranslation();

    const contentStyle = {
        fontFamily: 'Roboto, sans-serif',
        fontWeight: '300',
        padding: '20px',
        backgroundColor: 'white',
        lineHeight: '1.5',
        fontSize: '14px',
        color: 'black',
        margin: '10px',
        borderRadius: '10px',
    };

    const listStyle = {
        listStyleType: 'none',
        paddingLeft: '20px',
    };

    const listItemStyle = {
        marginBottom: '10px',
    };

    const bulletStyle = {
        fontWeight: 'bold',
        color: '#0056b3',
    };

    const linkStyle = {
        textDecoration: 'none',
    };

    const indicatorsState = useSelector((state) => state.privateIndicators);
    const indicatorsArray = Object.values(indicatorsState.value);
    const nonPrivateCount = indicatorsArray.filter(item => item.is_private === 0).length;

    return (
      <div style={contentStyle}>
            <p>{t('features.intro')}</p>
            <ul style={listStyle}>
                <li style={listItemStyle}>
                    <span style={bulletStyle}>{t('features.multilinguality.title')}:</span> {t('features.multilinguality.description')}
                </li>
                <li style={listItemStyle}>
                    <span style={bulletStyle}>{t('features.accordionMenu.title')}:</span> {t('features.accordionMenu.description')}
                </li>
                <li style={listItemStyle}>
                    <span style={bulletStyle}>{t('features.countryGroupings.title')}:</span> {t('features.countryGroupings.description')}
                </li>
                <li style={listItemStyle}>
                    <span style={bulletStyle}>{t('features.quickAccess.title')}:</span> {t('features.quickAccess.description')}
                </li>
                <li style={listItemStyle}>
                    <span style={bulletStyle}>{t('features.browseSearch.title')}:</span> {t('features.browseSearch.description', { count: nonPrivateCount })}
                </li>
                <li style={listItemStyle}>
                    <span style={bulletStyle}>{t('features.downloadByCategory.title')}:</span> {t('features.downloadByCategory.description')}
                </li>
                <li style={listItemStyle}>
                    <span style={bulletStyle}>{t('features.multipleCategorisation.title')}:</span> {t('features.multipleCategorisation.description')}
                </li>
                <li style={listItemStyle}>
                    <span style={bulletStyle}>{t('features.indicatorsDefinitions.title')}:</span> {t('features.indicatorsDefinitions.description')}
                </li>
                <li style={listItemStyle}>
                    <span style={bulletStyle}>{t('features.lastYearAvailableData.title')}:</span> {t('features.lastYearAvailableData.description')}
                </li>
                <li style={listItemStyle}>
                    <span style={bulletStyle}>{t('features.previewDownload.title')}:</span> {t('features.previewDownload.description')}
                </li>
            </ul>
        </div>
    );
}
