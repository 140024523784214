import { configureStore } from '@reduxjs/toolkit';
import countriesReducer, { fetchCountries } from './features/countriesSlice';
import indicatorsReducer, { fetchIndicators } from './features/indicatorsSlice';
import privateIndicatorsReducer, { fetchPrivateIndicators } from './features/privateIndicatorsSlice';
import unitsReducer, { fetchUnits } from './features/unitsSlice';
import categoriesReducer, { fetchCategories } from './features/categoriesSlice';
import sourcesReducer, { fetchSources } from './features/sourcesSlice';

export const store = configureStore({
    reducer: {
        countries: countriesReducer,
        indicators: indicatorsReducer,
        privateIndicators: privateIndicatorsReducer,
        units: unitsReducer,
        categories: categoriesReducer,
        sources: sourcesReducer,
    },
});

const retryAction = async (actionCreator, retries = 3) => {
    for (let i = 0; i < retries; i++) {
        try {
            await store.dispatch(actionCreator()).unwrap();
            break; // Break the loop if the action succeeds
        } catch (error) {
            if (i === retries - 1) throw error; // Throw the error on the last attempt
        }
    }
};

retryAction(fetchCountries);
retryAction(fetchPrivateIndicators);
retryAction(fetchIndicators);
retryAction(fetchUnits);
retryAction(fetchCategories);
retryAction(fetchSources);

// export default store;