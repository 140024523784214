import TabMenu from "./TabMenu";
import { createBrowserRouter, Link, RouterProvider } from "react-router-dom";
import logo from '../../assets/images/oicstat-logo.png';
import { Row, Col } from "antd";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'; // For translation support

export function Home() {
  const { t } = useTranslation(); // Translation hook

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    margin: '10px',
    borderRadius: '10px',
    backgroundColor: 'white',
    lineHeight: '22px',
  };

  const logoStyle = {
    marginRight: '20px',
    marginBottom: '10px',
    width: '120px',
  };

  const textStyle = {
    lineHeight: '22.5px',
    fontWeight: 'normal',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '300',
  };

  const indicatorsState = useSelector((state) => state.privateIndicators);
  const indicatorsArray = Object.values(indicatorsState.value);
  const nonPrivateCount = indicatorsArray.filter(item => item.is_private === 0).length;

  return (
    <Row style={containerStyle}>
      <Col>
        <img src={logo} alt={t('home.logoAlt')} style={logoStyle} />
      </Col>

      <Col style={{ width: '90%' }}>
        <p style={textStyle}>
          <strong>{t('home.databaseTitle')}</strong> {t('home.databaseDescription1')}  
          <a href="https://www.oic-oci.org/" target="_blank" rel="noopener noreferrer">
            {t('home.databaseLink')}
          </a> 
          {t('home.databaseDescription2')} 
          <strong> {t('home.oicstat')} </strong> {t('home.databaseDescription3')} 
          <Link to={"/features"}> {t('home.userFriendlyFeatures')} </Link> 
          {t('home.databaseDescription4')} {nonPrivateCount} <Link to={"/indicators_list"}>{t('home.indicatorsLink')}</Link> {t('home.databaseDescription5')}.
        </p>
      </Col>
    </Row>
  );
}
