import React, { useState, useEffect } from 'react';
import { Button, Space } from 'antd';
import { Navbar, Nav, Container, NavDropdown, Row, Col } from 'react-bootstrap';
import { CaretRightOutlined } from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style/external/externalTopbar.css';
import SesricLogo from '../../assets/images/sesric_logo.png';
import { useTranslation } from 'react-i18next';
import i18nn from 'i18n';


export function ExternalTopbar () {

  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  const { t } = useTranslation();

  useEffect(() => {
    i18nn.changeLanguage(language).then(() => {
      console.log(`Language changed to: ${language}`);
      console.log('Welcome Translation:', t('welcome')); // Fetch translation after language change
      console.log('Statistics Translation:', t('statistics')); // Fetch translation after language change
    });
  }, [language]);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('language', lang);
    window.location.reload();
    //console.log(`Language set to local storage: ${lang}`);
  };

  return (
    < >
      {/* Top bar for language, date, and social media */}
      {/* <Navbar style={{ backgroundColor: '#2F83C5', color: 'white', height:'40px' }} variant="dark">
            <Container className="justify-content-between">
              <Nav>
                <Nav.Link href="/" className="text-white">English</Nav.Link>
                <Nav.Link href="/ar" className="text-white">العربية</Nav.Link>
                <Nav.Link href="/fr" className="text-white">Français</Nav.Link>
              </Nav>
              <Navbar.Text className="text-white">
                14 March 2024, Thursday
              </Navbar.Text>
              <Nav>
                <Nav.Link href="https://twitter.com/sesric" className="text-white">
                  <FontAwesomeIcon icon={faTwitter} />
                </Nav.Link>
                <Nav.Link href="https://www.instagram.com/sesric1978" className="text-white">
                  <FontAwesomeIcon icon={faInstagram} />
                </Nav.Link>
                <Nav.Link href="https://www.youtube.com/@sesric1978" className="text-white">
                  <FontAwesomeIcon icon={faYoutube} />
                </Nav.Link>
                <Nav.Link href="mail-subscription-form.php" className="text-white">
                  <FontAwesomeIcon />
                </Nav.Link>
              </Nav>
            </Container>
          </Navbar>

           */}
      <Navbar bg="light" expand="lg" style={{ minHeight: '100%' }}>
        <Container fluid className="justify-content-between" >
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" >
            <Nav className="mx-auto"  >
              <Navbar.Brand href="https://www.sesric.org/index.php">
                <img src={SesricLogo} width="80" height="80" alt="SESRIC Logo" />
              </Navbar.Brand>
              <NavDropdown title={t('external_topbar.sesric')} id="mega-menu-dropdown" className="custom-mega-menu">
                <Container fluid>
                  <Row style={{ justifyContent: 'center' }}>
                    <Col md={6} className="mega-menu-column">
                      <h6 className="dropdown-header">{t('external_topbar.about')}</h6>
                      <div className="dropdown-divider" />

                      <NavDropdown.Item href="https://www.sesric.org/about-oic.php" className="menu-item">
                        <CaretRightOutlined /> {t('external_topbar.about_oic')}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/sesric-about.php" className="menu-item">
                        <CaretRightOutlined /> {t('external_topbar.about_sesric')}
                      </NavDropdown.Item>
                    </Col>
                    <Col md={6} className="mega-menu-column">
                      <h3 className="dropdown-header">{t('external_topbar.departments')}</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item href="https://www.sesric.org/sesric-department-statistics.php" className="menu-item">
                        <CaretRightOutlined /> {t('external_topbar.statistics_information')}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/sesric-department-research.php" className="menu-item">
                        <CaretRightOutlined /> {t('external_topbar.economic_social_research')}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/sesric-department-training.php" className="menu-item">
                        <CaretRightOutlined /> {t('external_topbar.training_technical_cooperation')}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/sesric-department-publication.php" className="menu-item">
                        <CaretRightOutlined /> {t('external_topbar.publication_it')}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/sesric-department-admin.php" className="menu-item">
                        <CaretRightOutlined /> {t('external_topbar.administration_finance')}
                      </NavDropdown.Item>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>

              <NavDropdown title={t("external_topbar.Statistics and Information")} id="mega-menu-dropdown" className="custom-mega-menu">
                <Container fluid>
                  <Row>
                    {/* First Column */}
                    <Col xs={12} md={2} className="mega-menu-column">
                      <h6 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">
                        {t("external_topbar.Databases, Directories and Rosters")}
                      </h6>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="http://oicstat.sesric.org" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.OIC Statistics Database (OICStat)")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/sesric_covid_19.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.SESRIC Covid-19 Pandemic Database")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/databases-nso.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.Directory of National Statistical")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oicdir.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.Directory of National Institutions")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/rose.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.Roster of Statistics Experts (ROSE)")}
                      </NavDropdown.Item>
                    </Col>

                    {/* Second Column */}
                    <Col xs={12} md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">
                        {t("external_topbar.Data Dissemination and Visualisation Tools")}
                      </h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/cif-home.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.OIC Countries in Figures (OIC-CIF)")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-ranker.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.OIC Ranker")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-tbf.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.OIC Top/Bottom Finder (OIC-TBF)")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/smc.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.SESRIC Motion Charts (SMC)")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-syb.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.Statistical Yearbook on OIC Member")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oso.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.OIC Statistical Outlook")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.oicstatcom.org/didyouknow.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.Did You Know? (DYK)")}
                      </NavDropdown.Item>
                    </Col>

                    {/* Continue with the other columns similarly */}
                    <Col xs={12} md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">
                        {t("external_topbar.Statistical Capacity Building (StatCaB) Programme")}
                      </h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.oicstatcom.org/statcab.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.About StatCaB Programme")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.oicstatcom.org/webinar-series.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.Statistical Experience Sharing Webinar Series")}
                      </NavDropdown.Item>
                    </Col>

                    <Col xs={12} md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">
                        {t("external_topbar.OIC Statistical Commission (OIC-StatCom)")}
                      </h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.oicstatcom.org/" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.About OIC-StatCom")}
                      </NavDropdown.Item>
                    </Col>

                    <Col xs={12} md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">
                        {t("external_topbar.Sustainable Development Goals (SDGs)")}
                      </h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-sdgs.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.Annual Progress Report on Prioritised SDGs")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=456" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.Prioritisation of SDGs by OIC Member Countries")}
                      </NavDropdown.Item>
                    </Col>

                    <Col xs={12} md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">
                        {t("external_topbar.Cooperation with International and Regional Organisations")}
                      </h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="http://www.oicstatcom.org/tqs.php" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.TQS and TQS-Y Integration Project")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/event-detail.php?id=2325" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.Subgroup on Gender Statistics Training (SGGST)")}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://unstats.un.org/unsd/ccsa/" className="menu-item"> 
                        <CaretRightOutlined /> {t("external_topbar.United Nations Committee of Experts on Big Data and Data Science for Official Statistics")}
                      </NavDropdown.Item>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>

              <NavDropdown title={t("external_topbar.Economic and Social Research")} id="mega-menu-dropdown" className="custom-mega-menu">
                <Container fluid>
                  <Row style={{ justifyContent: 'center' }}>
                    {/* Reports for Ministerial Conferences */}
                    <Col md={2} className="mega-menu-column">
                      <h6 className="dropdown-header">{t("external_topbar.Reports for Ministerial Conferences")}</h6>
                      <div className="dropdown-divider" />
                      <ul className="list-unstyled">
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/economic-outlook.php">
                          <CaretRightOutlined /> {t("external_topbar.OIC Economic Outlook")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/international-tourism.php">
                          <CaretRightOutlined /> {t("external_topbar.International Tourism in the OIC Countries: Prospects and Challenges")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-health.php">
                          <CaretRightOutlined /> {t("external_topbar.OIC Health Report")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/agriculture-food-security.php">
                          <CaretRightOutlined /> {t("external_topbar.Agriculture and Food Security in OIC Member Countries")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-water-report.php">
                          <CaretRightOutlined /> {t("external_topbar.OIC Water Report")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-labour-market-report.php">
                          <CaretRightOutlined /> {t("external_topbar.OIC Labour Market Report")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-education-scientific.php">
                          <CaretRightOutlined /> {t("external_topbar.Education and Scientific Development in OIC Countries")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-environment-report.php">
                          <CaretRightOutlined /> {t("external_topbar.OIC Environment Report")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-state-youth.php">
                          <CaretRightOutlined /> {t("external_topbar.State of Youth in OIC Member States")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-women-development-report.php">
                          <CaretRightOutlined /> {t("external_topbar.OIC Women and Development Report")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-family-values.php">
                          <CaretRightOutlined /> {t("external_topbar.Safeguarding Family Values and the Institution of Marriage in OIC Countries")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-state-elderly-report.php">
                          <CaretRightOutlined /> {t("external_topbar.State of Elderly Report")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-state-children-report.php">
                          <CaretRightOutlined /> {t("external_topbar.State of Children Report")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-disabilities-special-needs.php">
                          <CaretRightOutlined /> {t("external_topbar.People with Disabilities and Special Needs")}
                        </NavDropdown.Item>
                      </ul>
                    </Col>
                    {/* Strategic Programmes and Plans of Action */}
                    <Col md={2} className="mega-menu-column">
                      <h6 className="dropdown-header">{t("external_topbar.Strategic Programmes and Plans of Action")}</h6>
                      <div className="dropdown-divider" />
                      <ul className="list-unstyled">
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-2025-programme-of-action.pdf">
                          <CaretRightOutlined /> {t("external_topbar.OIC-2025 Programme of Action")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/OIC-Strategic-Health-Programme-of-Action-2014-2023.pdf">
                          <CaretRightOutlined /> {t("external_topbar.OIC Health Strategic Programme of Action 2014-2023")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-labour-strategy-2025.pdf">
                          <CaretRightOutlined /> {t("external_topbar.OIC Labour Strategy 2025")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/strategic-roadmap-islamic-tourism.pdf">
                          <CaretRightOutlined /> {t("external_topbar.Strategic Roadmap for Development of Islamic Tourism")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/OPAAW.PDF">
                          <CaretRightOutlined /> {t("external_topbar.OIC Plan of Action for the Advancement of Women")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/Youth-Strategy.pdf">
                          <CaretRightOutlined /> {t("external_topbar.OIC Youth Strategy")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-strategy-elderly.pdf">
                          <CaretRightOutlined /> {t("external_topbar.OIC Strategy on the Elderly")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-plan-of-action-disability.pdf">
                          <CaretRightOutlined /> {t("external_topbar.OIC Plan of Action on People with Disabilities")}
                        </NavDropdown.Item>
                      </ul>
                    </Col>
                    {/* Joint & Commissioned Reports */}
                    <Col md={2} className="mega-menu-column">
                      <h6 className="dropdown-header">{t("external_topbar.Joint & Commissioned Reports")}</h6>
                      <div className="dropdown-divider" />
                      <ul className="list-unstyled">
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=546">
                          <CaretRightOutlined /> {t("external_topbar.South-South Cooperation and the Gender Equality")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=525">
                          <CaretRightOutlined /> {t("external_topbar.Potential Impacts of the AfCFTA On Selected OIC Countries")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=533">
                          <CaretRightOutlined /> {t("external_topbar.South-South in Action: Transforming Potentials into Shared Prosperity")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=517">
                          <CaretRightOutlined /> {t("external_topbar.The Role of the Private Sector in Supporting the 2030 Agenda for Sustainable Development")}
                        </NavDropdown.Item>
                        <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-detail.php?id=528">
                          <CaretRightOutlined /> {t("external_topbar.Regional Comprehensive Economic Partnership and Implications for OIC Countries")}
                        </NavDropdown.Item>
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>


              <NavDropdown title={t("external_topbar.training_and_technical_cooperation")} id="mega-menu-dropdown" className="custom-mega-menu">
                <Container fluid>
                  <Row style={{ justifyContent: 'center' }}>
                    {/* SESRIC Capacity Building Programmes */}
                    <Col md={4} className="mega-menu-column">
                      <h3 className="dropdown-header">{t("external_topbar.sesric_capacity_building_programmes")}</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item href="https://www.sesric.org/tcd-cab.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.all_programmes")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/cbp-agricab.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.agriculture")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/cbp-ctp.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.cotton")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/cbp-water.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.water")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/cbp-oic-drm.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.risk_management")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/cbp-environment.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.environment")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/cbp-centralbanks.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.central_banks")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/tcd-cab.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.more")}
                      </NavDropdown.Item>
                    </Col>

                    {/* Technical Cooperation */}
                    <Col md={4} className="mega-menu-column">
                      <h3 className="dropdown-header">{t("external_topbar.technical_cooperation")}</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item href="https://www.sesric.org/technical_cooperation.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.main_page")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/reverse-linkage.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.reverse_linkages")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/tcd-activities-projects.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.activities_projects")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/tcd-networks.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.networks")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/tcd-portals.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.portals")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/mous-agreements.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.mous")}
                      </NavDropdown.Item>
                    </Col>

                    {/* OIC-VET Programme */}
                    <Col md={4} className="mega-menu-column">
                      <h3 className="dropdown-header">{t("external_topbar.oic_vet_programme")}</h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item href="https://www.oicvet.org/" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.main_page")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.sesric.org/files/article/693.pdf" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.oic_tvet_strategic_roadmap")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="http://www.oicvet.org/mac.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.monitoring_advisory_committee")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="http://www.oicvet.org/index.php" className="menu-item">
                        <CaretRightOutlined /> {t("external_topbar.activities")}
                      </NavDropdown.Item>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>

              <NavDropdown title={t('external_topbar.Publications')} id="mega-menu-dropdown" className="custom-mega-menu">
                <Container fluid>
                  <Row style={{ justifyContent: 'center' }}> 
                    {/* Technical Reports for Ministerial Meeting */}
                    <Col md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">
                        {t('external_topbar.Technical Reports for Ministerial Meeting')}
                      </h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-aer.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.OIC Economic Outlook')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-sdgs.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.Annual Progress Report on Prioritized SDGs')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-water.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.OIC Water Report')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-women-development-report.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.OIC Women and Development Report')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-tourism.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.International Tourism in the OIC')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-environment-report.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.OIC Environment Report')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-health.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.OIC Health Report')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-labour.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.OIC Labour Market Report')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/oic-state-youth.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.State of Youth in OIC Member States')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-agriculture.php" className="menu-item">
                        <CaretRightOutlined />{t('Agriculture and Food Security in OIC Member Countries')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-education.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.Education and Scientific Development in OIC Countries')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publication-oic-family-values.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.Safeguarding Family Values and the Institution of Marriage in OIC Countries')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-state-elderly-report.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.State of Elderly in OIC Member Countries')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-state-children-report.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.State of Children in OIC Member Countries')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-disabilities-special-needs.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.People with Disabilities and Special Needs')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/files/article/693.pdf" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.OIC-TVET Strategic Roadmap 2020-2025')}
                      </NavDropdown.Item>
                    </Col>

                    {/* Regular/Series Publications */}
                    <Col md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">
                        {t('external_topbar.Regular/Series Publications')}
                      </h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-syb.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.Statistical Yearbook on OIC Member Countries')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-jecd.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.Journal of Economic Cooperation and Development')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-enewsletter-training.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.Training Cooperation e-Newsletter')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-newsletter-oicstat.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.SESRIC Statistics Newsletter')}
                      </NavDropdown.Item>
                    </Col>

                    {/* Strategic Documents */}
                    <Col md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">
                        {t('external_topbar.Strategic Documents')}
                      </h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/files/article/601.pdf" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.OIC Labour Market Strategy 2025')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/files/article/610.pdf" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.Strategic Roadmap for Development of Islamic Tourism')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/files/article/480.pdf" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.OIC Strategic Health Programme of Action 2014-2023')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/files/Youth-Strategy.pdf" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.OIC Youth Strategy')}
                      </NavDropdown.Item>
                    </Col>

                    {/* Special Reports */}
                    <Col md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">
                        {t('external_topbar.Special Reports')}
                      </h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-oic-state-youth-report.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.State of Youth in OIC Member States')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-education.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.State of Education in OIC Countries')}
                      </NavDropdown.Item>
                    </Col>

                    {/* Statistical Publications */}
                    <Col md={2} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} className="dropdown-header">
                        {t('external_topbar.Statistical Publications')}
                      </h3>
                      <div className="dropdown-divider" />
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-circulars.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.OIC Statistical Circulars')}
                      </NavDropdown.Item>
                      <NavDropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8'}} href="https://www.sesric.org/publications-handbooks.php" className="menu-item">
                        <CaretRightOutlined />{t('external_topbar.Handbooks on Statistics')}
                      </NavDropdown.Item>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>


              <NavDropdown title={t('external_topbar.news_center')} id="news-center-dropdown" className="custom-mega-menu">
                <Container fluid>
                  <Row className="justify-content-md-center">
                    {/* News Center Content */}
                    <Col md={4} className="mega-menu-column">
                      <h3 style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8' }} className="dropdown-header">
                        {t('external_topbar.news_center')}
                      </h3>
                      <div className="dropdown-divider" />
                      <ul className="list-unstyled">
                        <NavDropdown.Item
                          style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8' }}
                          href="https://www.sesric.org/calendar.php"
                          className="menu-item"
                        >
                          <CaretRightOutlined /> {t('external_topbar.calendar')}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8' }}
                          href="https://www.sesric.org/activities-archive.php"
                          className="menu-item"
                        >
                          <CaretRightOutlined /> {t('external_topbar.activities_archive')}
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          style={{ whiteSpace: 'normal', wordWrap: 'break-word', lineHeight: '1.8' }}
                          href="https://www.sesric.org/activities-announcements.php"
                          className="menu-item"
                        >
                          <CaretRightOutlined /> {t('external_topbar.announcements')}
                        </NavDropdown.Item>
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>
              <Nav.Link style={{ color: '#2F83C5', fontSize: '15px' }} href="https://www.sesric.org/contact-info.php">
                {t('external_topbar.contact_us')}
              </Nav.Link>
              <Space style={{marginLeft: '30px', marginBottom: '10px', display:"none"}}>
                <Button.Group>
                  <Button
                    type={language === 'en' ? 'primary' : 'default'}
                    onClick={() => changeLanguage('en')}
                  >
                    En
                  </Button>
                  <Button
                    type={language === 'fr' ? 'primary' : 'default'}
                    onClick={() => changeLanguage('fr')}
                  >
                    Fr
                  </Button>
                  <Button
                    type={language === 'ar' ? 'primary' : 'default'}
                    onClick={() => changeLanguage('ar')}
                  >
                    Ar
                  </Button>
                </Button.Group>
              </Space>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}