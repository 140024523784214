import React from 'react'

import Topbar from '../topbar'
// import SettingOffCanvas from '../../components/setting/SettingOffCanvas'
import { Outlet } from "react-router-dom";
import { Layout } from 'antd';
import TabMenu from 'pages/external/TabMenu';
import { ExternalTopbar } from 'pages/external/ExternalTopbar';

const { Header, Content } = Layout;


export const AppLayout = () => {
  return (
    <>
      <Layout className='layout-root'>
        <Header className='akd-header'>
          <ExternalTopbar />
        </Header>
        <Layout className='layout-page' style={{ minHeight: '100%' }}>
          <Layout className='' style={{ padding: "16px" }}>
            
            <Content>
              
              <TabMenu/>
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};