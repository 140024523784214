import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './languages/en.json';
import fr from './languages/fr.json';
import ar from './languages/ar.json';

const savedLanguage = localStorage.getItem('language') || 'en';
console.log("Saved language in i18n.js:", savedLanguage);  
console.log("Language resources in i18n.js:", { en, fr, ar });  

i18n
  .use(initReactI18next) 
  .init({
    resources: {
      en: { translation: en.translation },
      fr: { translation: fr.translation },
      ar: { translation: ar.translation },
    },
    lng: savedLanguage, 
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, 
    },
  })
  .then(() => console.log("i18n initialization successful"))
  .catch((error) => console.error("i18n initialization error:", error));

export default i18n;


