import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Table } from 'antd';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { FilterOutlined, LineOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { FilterDropdown } from './FilterDropdown';
import { createFilterOptions } from './Utils';
import { formatDataTableValue } from './Utils';
import { renderPercentageChange } from './Utils';
import './datatable.css';
import { IndicatorShowExternal } from './modals/IndicatorShowExternal';
import { useTranslation } from 'react-i18next';

export default function DataTable({ columns, data, loading = false, widths, calculatePercentage = false, percentageCols, className, decimalSymbol, decimalPlaces, colYearView = false, showAll = false, yearRange = [], yearOrder = "Descending", showGrowthRate = false, showIndexColumn = true, isAdmin = true}) {

  const columnWidths = widths || [];
  const [logData, setLogData] = useState(data);
  const countriesState = useSelector((state) => state.countries);
  const indicatorsState = useSelector((state) => state.indicators);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 250,
    position: ["topLeft", "bottomLeft"],
    pageSizeOptions: ["10", "50", "100", "250"]
  });
  const countryFilterOptions = useMemo(() => createFilterOptions(logData, 'c_name'), [logData]);
  const indicatorFilterOptions = useMemo(() => createFilterOptions(logData, 'ind_name'), [logData]);
  const unitFilterOptions = useMemo(() => createFilterOptions(logData, 'u_name'), [logData]);
  const categoryFilterOptions = useMemo(() => createFilterOptions(logData, 'cat_name'), [logData]);
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

  const index_columns = [
    { title: "#", key: "index", render: (text, record, index) => index },
  ];

  useEffect(() => {
    if (showAll) {
      setPagination(false);
    }
  }, [showAll]);

  const default_columns = [
    { title: "Region Name", dataIndex: "r_name", key: 12, width: 'auto' },
    { title: "Country Code", dataIndex: "c_code", key: 1, width: 'auto' },
    { title: "Indicator Code", dataIndex: "ind_code", key: 2, width: 'auto' },
    {
      title: "Country",
      dataIndex: "c_name",
      key: 3,
      width: 'auto',
      filters: countryFilterOptions,
      onFilter: (value, record) => record.c_name === value,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <FilterDropdown
          options={countryFilterOptions}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onCell: (record) => ({
        style: {
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        },
      }),
    },
    {
      title: "Category",
      dataIndex: "cat_name",
      key: 4,
      width: 'auto',
      filters: categoryFilterOptions,
      onFilter: (value, record) => record.cat_name === value,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <FilterDropdown
          options={categoryFilterOptions}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onCell: (record) => ({
        style: {
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        },
      }),
    },
    {
      title: "Indicator",
      dataIndex: "ind_name",
      key: 5,
      width: 'auto',
      ellipsis: true,
      filters: indicatorFilterOptions,
      onFilter: (value, record) => record.ind_name === value,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <FilterDropdown
          options={indicatorFilterOptions}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onCell: (record) => ({
        style: {
          whiteSpace: 'normal',
          wordBreak: 'break-word',
          maxWidth: '900px',
        },
      }),
    },
    {
      title: "Unit",
      dataIndex: "u_name",
      key: 6,
      width: 'auto',
      filters: unitFilterOptions,
      onFilter: (value, record) => record.u_name === value,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <FilterDropdown
          options={unitFilterOptions}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onCell: (record) => ({
        style: {
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        },
      }),
    },
    { title: "Year", dataIndex: "d_year", key: 7, width: 'auto' },
    { title: "Last Update Date", dataIndex: "d_date", key: 8, width: 'auto' },
    {
      title: "Aggregation Type",
      dataIndex: "agg_type",
      key: 9,
      width: 'auto'
    },
    {
      title: "Value",
      dataIndex: "d_value",
      key: 10,
      width: 'auto'
    }
  ];

  const extra_columns = [
    { title: "First Year", dataIndex: "d_year_first", key: 6, width: 'auto' },
    { title: "First Value", dataIndex: "d_value_first", key: 7, width: 'auto' },
    { title: "Last Year", dataIndex: "d_year_last", key: 8, width:  'auto' },
    { title: "Last Value", dataIndex: "d_value_last", key: 9, width: 'auto' },
    { title: "Live Value", dataIndex: "d_value_live", key: 10, width:  'auto' },
    { title: "Test Value", dataIndex: "d_value_test", key: 11, width:  'auto' },
  ]

  const yearColumns = yearRange.map(year => {
    const yearValue = year.toString();
    return {
      title: yearValue,
      dataIndex: yearValue,
      key: yearValue,
      width: 'auto',
    };
  });

  if (yearOrder === "Descending") {
    yearColumns.reverse();
  }


  let final_columns = [].concat(
    showIndexColumn ? index_columns : []
  ).concat(
    columns ? default_columns.filter(column => columns.includes(column.dataIndex)) : default_columns
  ).concat(
    columns ? extra_columns.filter(column => columns.includes(column.dataIndex)) : []
  ).concat(
    calculatePercentage ? { title: "Percentage", dataIndex: "percentage", key: 10, width:  'auto' } : []
  ).concat(
    colYearView ? yearColumns : []
  );

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
  };

  const rowClassName = (record, index) => {
    return "table-row";
  };

  const indicatorsLoaded = indicatorsState && indicatorsState.value;
  const countriesLoaded = countriesState && countriesState.value;

  useEffect(() => {
    if (data && indicatorsLoaded && countriesLoaded) {
      let updatedData = data.map((element, index) => {
        element.key = index;
        if (columns.includes("ind_name")) {
          if(indicatorsState.value[element.ind_code] != null){
            if(isAdmin){
              element.ind_name = indicatorsState.value[element.ind_code].ind_name_eng;
            }
            else{
              if(language == "en"){
                element.ind_name = indicatorsState.value[element.ind_code].ind_name_eng;
              }
              else if(language == "fr"){
                element.ind_name = indicatorsState.value[element.ind_code].ind_name_fr;
              }
              else if(language == "ar"){
                element.ind_name = indicatorsState.value[element.ind_code].ind_name_arb;
              }
              else{
                element.ind_name = indicatorsState.value[element.ind_code].ind_name_eng;
              }
            }
          }
          if (!columns.includes("r_name")) {
            if(isAdmin){
              if(countriesState.value[element.c_code] != undefined){
                element.c_name = countriesState.value[element.c_code].c_short_name_eng;
              }
            }
            else{
              if(language == "en"){
                element.c_name = countriesState.value[element.c_code].c_short_name_eng;
              }
              else if(language == "fr"){
                element.c_name = countriesState.value[element.c_code].c_short_name_fr;
              }
              else if(language == "ar"){
                element.c_name = countriesState.value[element.c_code].c_short_name_arb;
              }
              else{
                element.c_name = countriesState.value[element.c_code].c_short_name_eng;
              }
            }
          }

          //element.formatted_d_value = formatDataTableValue(element.d_value, decimalSymbol, decimalPlaces);
          //element.formatted_d_value =  element.d_value === null ? null : Number(element.d_value).toFixed(3); 
          element.formatted_d_value = element.d_value === null
            ? null
            : Number(element.d_value)
                .toFixed(decimalPlaces)
                .replace('.', decimalSymbol)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          
        }
        return element;
      });

      //subtract r_name from columns if c_name is also included
      if (columns.includes("r_name") && columns.includes("c_name")) {
        const index = columns.indexOf("r_name");
        columns.splice(index, 1);
      }

      if (calculatePercentage) {
        const updatedDataWithPercentage = updatedData.map((element, index) => {
          const percentage = (element.d_value_last - element.d_value_first) / element.d_value_first * 100;
          element.percentage = percentage.toFixed(2);

          return element;
        });
        setLogData(updatedDataWithPercentage);
      } else {
        setLogData(updatedData);
      }
    }
  }, [data, indicatorsLoaded, countriesLoaded]);

  const renderPercentageDifference = (percentageDifference) => {
    // Define color and icon based on the percentage difference
    let color = 'black', Icon = LineOutlined;
    if (percentageDifference > 0) {
      color = 'green';
      Icon = UpOutlined;
    } else if (percentageDifference < 0) {
      color = 'red';
      Icon = DownOutlined;
    }

    if (percentageDifference !== 0) {
      return (
        <span style={{ color, fontWeight: "bold", float: "right" }}>
          ({percentageDifference}%) <Icon />
        </span>
      );
    } else {
      return <>
        <span style={{ color, fontWeight: "bold", float: "right" }}>
          ({percentageDifference}%) <Icon />
        </span>
      </>
    }
  };

  function sortValue(colName, a, b, sortOrder = "ascend") {
    let direction = sortOrder === "ascend" ? 1 : -1;
    // Handle null values to ensure they always sort to the end
    const isANull = a[colName] === null || a[colName] === undefined;
    const isBNull = b[colName] === null || b[colName] === undefined;

    if (isANull && isBNull) {
      return 0; // Both are null, considered equal
    } else if (isANull) {
      return direction; // 'a' is null, place it at the end regardless of sort order
    } else if (isBNull) {
      return -direction; // 'b' is null, place it at the end regardless of sort order
    }

    // If neither value is null, perform a standard comparison
    const valA = parseFloat(a[colName]);
    const valB = parseFloat(b[colName]);
    return valA - valB;
  }

  const fColumns = final_columns.map((column) => {

    if (column.dataIndex) {
      return {
        ...column,
        sorter: {
          compare: (a, b, sortOrder) => {
            if (column.dataIndex === "percentage") {
              return a.percentage - b.percentage;
            } else if (["d_value", "d_value_first", "d_value_last", "d_value_live", "d_value_test", "d_date"].includes(column.dataIndex)) {
              return sortValue(column.dataIndex, a, b, sortOrder);
            } else if (yearRange.includes(parseInt(column.dataIndex))) {
              return sortValue(column.dataIndex, a, b, sortOrder);
            } else if (typeof a[column.dataIndex] === "number") {
              return a[column.dataIndex] - b[column.dataIndex];
            } else {
              return a[column.dataIndex].localeCompare(b[column.dataIndex]);
            }
          },
        },
        filterMultiple: true,
        render: (text, record) => {
          switch (column.dataIndex) {
            case "c_code":
              return <Link to={`https://www.sesric.org/cif.php?c_code=${record.c_code}`}>{countriesState.value[record.c_code].c_short_name_eng}</Link>;
            case "ind_code":
              return <a onClick={() => handleIndicatorClick(record.ind_code)}>{indicatorsState.value[record.ind_code]?.ind_name_eng || "Unknown"}</a>;
            case "ind_name":
              return <a onClick={() => handleIndicatorClick(record.ind_code)}>{record.ind_name || "Unknown"}</a>;
            case "c_name":
              return <a href={`https://www.sesric.org/cif.php?c_code=${record.c_code}`} target="_blank" rel="noopener noreferrer">{record.c_name}</a>
            case "d_value":
              return record.formatted_d_value;
            case "d_value_live":
            case "d_value_test":
            case "d_value_first":
            case "d_value_last":
              return formatDataTableValue(text, decimalSymbol, decimalPlaces);
            case "percentage":
              return renderPercentageDifference(text);
            default:
              if (yearRange.includes(parseInt(column.dataIndex))) {
                if (showGrowthRate) {
                  return renderPercentageChange(null, column.dataIndex, text, record[column.dataIndex + "_growth"], null, record[column.dataIndex + "_prev_date"], record[column.dataIndex + "_prev_data"], true, decimalSymbol, decimalPlaces);
                } else {
                  return formatDataTableValue(text, decimalSymbol, decimalPlaces);
                }
              } else {
                return text;
              }
          }
        },
        onHeaderCell: () => { return { onMouseEnter: () => { } }; },
        className: `oicstat-column-${column.dataIndex}`
      };
    }
    return column;
  });
  const handleIndicatorClick = (indicatorCode) => {
    setSelectedIndicator(indicatorsState.value[indicatorCode]);
    setModalVisible(true);
    console.log(selectedIndicator);
  }
  const handleModalCancel = () => {
    setModalVisible(false);
    setSelectedIndicator(null);
  }

  const tableRef = useRef(null);
  const scrollableWrapperRef = useRef(null);
  const isScrolling = useRef(false);
  const startX = useRef(0);
  const startY = useRef(0);
  const scrollLeft = useRef(0);
  const scrollTop = useRef(0);
  const scrollMultiplier = 1.5; 

  const onMouseDown = (e) => {
    isScrolling.current = true;
    startX.current = e.pageX;
    startY.current = e.pageY;
    scrollLeft.current = scrollableWrapperRef.current.scrollLeft;
    scrollTop.current = scrollableWrapperRef.current.scrollTop;
    document.body.style.cursor = 'grabbing';
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    //console.log("Mouse down:", { startX: startX.current, startY: startY.current, scrollLeft: scrollLeft.current, scrollTop: scrollTop.current });
  };

  const onMouseMove = (e) => {
    if (!isScrolling.current) return;
    e.preventDefault();
    const x = e.pageX;
    const y = e.pageY;
    const walkX = (x - startX.current) * scrollMultiplier; 
    const walkY = (y - startY.current) * scrollMultiplier; 

    scrollableWrapperRef.current.scrollLeft = scrollLeft.current - walkX;
    scrollableWrapperRef.current.scrollTop = scrollTop.current - walkY; 
    //console.log("Mouse move:", { x, y, walkX, walkY, newScrollLeft: scrollableWrapperRef.current.scrollLeft, newScrollTop: scrollableWrapperRef.current.scrollTop });
  };

  const onMouseUp = () => {
    if (!isScrolling.current) return;
    isScrolling.current = false;
    document.body.style.cursor = 'default';
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
    //console.log("Mouse up");
  };

  useEffect(() => {
    const scrollableWrapper = scrollableWrapperRef.current;
    scrollableWrapper.addEventListener('mousedown', onMouseDown);
    return () => {
        scrollableWrapper.removeEventListener('mousedown', onMouseDown);
    };
  }, []);


  return (
    <div className="tableDiv">
        <div className="scrollable-wrapper" ref={scrollableWrapperRef} style={{ maxHeight: '80vh' }}>
          <div className="scrollable-content" ref={tableRef} style={{marginTop: '-50px'}}>
          <Table
          columns={fColumns}
          dataSource={logData}
          rowClassName={rowClassName}
          size="small"
          pagination={pagination}
          onChange={handleTableChange}
          loading={loading}
          showSorterTooltip={false}
          scroll={{ x: 'max-content'}}           
        />
          </div>
        </div>
          {selectedIndicator && 
          
            <IndicatorShowExternal
            visible={modalVisible}
            record={selectedIndicator}
            onCancel={handleModalCancel}
            ></IndicatorShowExternal>
        }      
    </div>    
  );
}
