import React from "react";
import { AppLayout } from "./layout";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home } from "pages/external/Home";
import { NoMatch } from "pages/NoMatch";
import { useSelector } from 'react-redux';
import TabMenu from "pages/external/TabMenu";
import { Query } from "pages/external/Query";
import { Features } from "pages/external/Features";
import { IndicatorsList } from "pages/external/IndicatorsList";
import { DataSource } from "pages/external/DataSource";
import { Feedback } from "pages/external/Feedback";
export const DefaultRouterProvider = () => {
  const DefaultRouter = [
    
    {
      element: <AppLayout />,
      children: [
        { path: "/", element: <Home />, },
        { path: "/query", element: <Query />, },
        { path: "/features", element: <Features />, },
        { path: "/indicators_list", element: <IndicatorsList />, },
        { path: "/data_source", element: <DataSource />, },
        { path: "/feedback", element: <Feedback />, },
      ].filter(x => x !== null),
      errorElement: <NoMatch />
    },


  ].filter(x => x !== null);
  const router = createBrowserRouter(DefaultRouter);
  return (
    <>
      
      <RouterProvider router={router} />
    </>

  );
}